import React, { useEffect, useState } from "react";
import LanguageSwitcher from "../LanguageSwitcher";
import { useLanguage } from "../LanguageContext";
import colors from "../../resources/styles/variables.js";
import translate from "../GetTranslations.js";
import { Logo, Menu, Home } from "../GetIcons.js";
import { Link } from 'react-scroll';
import { Link as NewLink } from "react-router-dom";
import "./Header.scss";

const Header = (props) => {
  const [newClass, setNewClass] = useState(props.type === 1 ? "scrolled" : "");
  const [fillColor, setFillColor] = useState(props.type === 1 ? colors.white : colors.birch);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language } = useLanguage();

  const homeText = translate("home", language);
  const goHomeText = translate("goHomeText", language);
  const aboutText = translate("about", language);
  const servicesText = translate("services", language);
  const teamText = translate("team", language);
  const contactUsText = translate("contact_us", language);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 || props.type === 1) {
        setNewClass("scrolled");
        setFillColor(colors.white)
      } else {
        setNewClass("");
        setFillColor(colors.birch)
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  return (
    <header className={`header ${newClass}`}>
      <div className="menu" onClick={toggleMenu}>
        <Menu fillColor={fillColor} />
      </div>
      <div className="header-logo-column">
        {props.type === 1 ? (
          <>
            <NewLink to="/"
              activeClass="active"><Logo fillColor={fillColor} /></NewLink>
          </>
        ) :
          (<Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={toggleMenu}
          >
            <Logo fillColor={fillColor} />
          </Link>)}

      </div>

      <nav className={`header_nav ${isMenuOpen ? 'opened' : 'closed'}`}>

        {props.type === 1 ? (
          <ul>
            <li>
              <NewLink to="/" className="NewLink"><Home fillColor={fillColor} />{goHomeText}</NewLink>
            </li>
          </ul>
        ) :
          (<ul>
            <li>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
              >
                {homeText}
              </Link>
            </li>
            <li>

              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
              >
                {aboutText}
              </Link>
            </li>
            <li>

              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
              >
                {servicesText}
              </Link>
            </li>
            {/* <li>

              <Link
                activeClass="active"
                to="team"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
              >
                {teamText}
              </Link>
            </li> */}
            <li>
              <Link
                activeClass="active"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggleMenu}
              >
                {contactUsText}
              </Link>
            </li>
          </ul>)}


      </nav>
      <div className="main-langs">
        <LanguageSwitcher type={props.type} />
      </div>
    </header>
  );
};

export default Header;
