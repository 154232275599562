import React from "react";
import ProcessTexts from './ProcessTexts';
import translate from '../../components/GetTranslations.js';
import { useLanguage } from '../../components/LanguageContext';
import './Process.scss';

const Process = () => {
    const { language } = useLanguage();
    const the_process = translate("the_process", language)
    const we_offer = translate("we_offer", language)

    const currentProcessTexts = ProcessTexts[language] || [];
    return (

        <div className="process-container" id="process" >
            <span className="page-name">{the_process}</span>
            <p className="process-text">{we_offer}</p>
            <div className="process-item-container">
                {currentProcessTexts.map((text, index) => (
                    <div key={index} className="process-item">
                        <div className="line">
                            <div className="dot">
                                <p>{index + 1}</p>
                            </div>
                            <div className="dashed-line"></div>
                        </div>

                        <p className="process-item-title">{text.title}</p>
                        <p className="process-item-text">{text.text}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Process