import React, { useState, useEffect } from "react";
import { useLanguage } from "./LanguageContext";
import Langs from "../resources/dictions/langs.json";
import { Globe, LangDown } from "./GetIcons";
import colors from "../resources/styles/variables";

const LanguageSwitcher = (props) => {
  const { language, setLanguage } = useLanguage();
  const [newClass, setNewClass] = useState(props.type === 1 ? "scrolledLang" : "");
  const [isOpen, setIsOpen] = useState(false);
  const [fillColor, setFillColor] = useState(props.type === 1 ? colors.white : colors.birch);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("lang");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }

    const handleScroll = () => {
      if (window.scrollY > 50 || props.type === 1) {
        setFillColor(colors.white)
        setNewClass('scrolledLang')
      } else {
        setFillColor(colors.birch)
        setNewClass('')
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
  }, [newClass]);

  const handleChangeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("lang", newLanguage);
    setIsOpen(false);
  };

  return (
    <div className="language-container">
      <div className="dropdown">
        <div className="selected-language" onClick={() => setIsOpen(!isOpen)}>
          <div className="selected">
            {Langs[language]}
            <div className="globe">
              <LangDown fillColor={fillColor} />
              <Globe fillColor={fillColor} />
            </div>
          </div>
        </div>
        {isOpen && (
          <div className={`dropdown-content ${newClass}`}>
            {Object.entries(Langs).map(([key, value]) => (
              <div
                key={key}
                className="language-option"
                onClick={() => handleChangeLanguage(key)}
              >
                <p>{value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
