import React, { useRef, useState } from 'react';
import translate from '../../components/GetTranslations.js';
import { Location, Telegram, LinkedIn, Email } from '../../components/GetIcons.js';
import { useLanguage } from '../../components/LanguageContext';
import colors from "../../resources/styles/variables.js";
import emailjs from 'emailjs-com'; // Import EmailJS library
import "./Contact.scss";

const Contact = () => {
    const { language } = useLanguage();
    const [alertSubmit, setAlertSubmit] = useState('');
    const [alertEmailColor, setAlertEmailColor] = useState('');
    const [alertFullNameColor, setAlertFullNameColor] = useState('');
    const [alertTextColor, setAlertTextColor] = useState('');
    const [alertSubmitColor, setAlertSubmitColor] = useState('red');
    const [formData, setFormData] = useState({
        full_name: '',
        email_telephone: '',
        project_description: ''
    });

    const form = useRef()
    const pageName = translate("contact_us", language).toUpperCase();
    const intro = translate("intro", language)
    const email = translate("email", language)
    const email_1 = translate("email_1", language)
    const address = translate("address", language)
    const address_1 = translate("address_1", language)
    const address_en = translate("address_1", 'en')
    const links = translate("links", language)
    const cdm_li = translate("cdm_li", language)
    const cdm_tg = translate("cdm_tg", language)
    const fullname = translate("fullname", language)
    const describe = translate("describe", language)
    const send = translate("send", language)
    const success = translate("success", language)
    const failed = translate("failed", language)

    const isEmailValid = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const { full_name, email_telephone, project_description } = formData;
        setAlertEmailColor('black');
        setAlertTextColor('black');
        setAlertFullNameColor('black');

        if (!isEmailValid(email_telephone)) {
            setAlertEmailColor('red');
            return;
        }

        if (!project_description) {
            setAlertTextColor('red');
            return;
        }

        if (!full_name) {
            setAlertFullNameColor('red');
            return;
        }
        emailjs.send("service_x5ck08j", "template_cd5rfvq", {
            full_name: full_name,
            email_telephone: email_telephone,
            project_description: project_description,
        }, 'aw33Jqe6dVEmG5LdW').then((result) => {
            setAlertSubmit(success);
            setAlertSubmitColor('green')
        }, (error) => {
            setAlertSubmit(failed);
            setAlertSubmitColor('red')
        });

    };
    return (
        <div className="contact-us" id="contact-us">
            <span className="page-name">{pageName}</span>
            <p className="intro-text">{intro}</p>
            <div className='contact-us-content'>
                <div className='contact-div'>
                    <p className='contact-title'>{email}</p>
                    <p className='contact-text email'>
                        <a href={`mailto:${email_1}`} target="_blank" rel="noreferrer" className='email'>
                            <Email fillColor={colors.birch} />
                        </a>
                        {email_1}
                    </p>
                    <p className='contact-title'>{address}</p>
                    <p className='contact-text'>
                        <a href={`https://www.google.com/maps/place/${encodeURIComponent(address_en)}`} target="_blank" rel="noopener noreferrer">
                            <Location fillColor={colors.birch} />
                        </a>
                        {address_1}
                    </p>

                    <p className='contact-title'>{links}</p>
                    <div className='contact-icons'>
                        <a href={cdm_tg} target="_blank" rel="noreferrer"><Telegram fillColor={colors.birch} /></a>
                        <a href={cdm_li} target="_blank" rel="noreferrer"><LinkedIn fillColor={colors.birch} /></a>
                    </div>
                </div>
                <div className='contact-form'>
                    <form ref={form} onSubmit={handleSubmit}>
                        <div className='form-grouped'>
                            <div className="form-group">
                                <label htmlFor="full_name" style={{ color: alertFullNameColor }}>{fullname} * </label>
                                <input type="text" id="full_name" name="full_name" required
                                    onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email_telephone" style={{ color: alertEmailColor }}>{email} *</label>
                                <input type="email" id="email_telephone" name="email_telephone" required
                                    onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="project_description" style={{ color: alertTextColor }}>{describe} *</label>
                            <input type="text" id="project_description" name="project_description" required
                                onChange={handleInputChange} />
                        </div>
                        <div className="form-group">
                            <button type="submit">{send}</button>
                            <p style={{ color: alertSubmitColor }}>{alertSubmit}</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;
