import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Home from "./Home/Home";
import About from "./About/About";
import Process from "./Process/Process";
import Services from "./Services/Services";
import Team from "./Team/Team";
import Contact from "./Contact/Contact";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

function Main() {
  useEffect(() => {
    ReactGA.initialize("G-HX707GVEF3");
  }, []);
  return (
    <div className="Main">
      <Header />
      <Home />
      <About />
      <Process />
      <Services />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
}
export default Main;
