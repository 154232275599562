import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LanguageProvider } from "./components/LanguageContext";
import { Terms, Politics } from "./pages/Terms/Terms";
import Main from "./pages/Main";
import "./resources/styles/variables.scss";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Router>
      <div className="App">
        <LanguageProvider>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/politics" element={<Politics />} />
          </Routes>
        </LanguageProvider>
      </div>
    </Router>
  );
}

export default App;
