import React, { useState } from "react";
import servicesData from "./ServicesData";
import translate from "../../components/GetTranslations.js";
import { useLanguage } from "../../components/LanguageContext";

import ReactGA from "react-ga4";
import "./Services.scss";

const Services = () => {
  const [expandedServiceIndex, setExpandedServiceIndex] = useState(-1);
  const { language } = useLanguage();
  const our_services = translate("our_services", language);
  const currentՍervicesData = servicesData[language] || [];

  const handleServiceClick = (index) => {
    setExpandedServiceIndex((prevIndex) => (prevIndex === index ? -1 : index));
    const serviceName = servicesData[language][index].title;
    ReactGA.event({
      category: "Service",
      action: "Clicked",
      label: serviceName,
    });
  };

  return (
    <div className="services" id="services">
      <div className="oval"></div>
      <h2 className="services_heading">{our_services}</h2>
      <div className="services_container">
        {currentՍervicesData.map((service, index) => (
          <div
            className={`service ${
              index === expandedServiceIndex ? "expanded" : ""
            }`}
            key={index}
          >
            <div className="vector">
              <p className="vector-text">{service.id}</p>
            </div>
            <div className="service_info">
              <h3 className="service_title">{service.title}</h3>
              <p className="service_description">{service.description} </p>
              <p className="plus" onClick={() => handleServiceClick(index)} />
            </div>

            <p className="service_full_description">
              {service.full_decription}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
