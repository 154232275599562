// translations.js
import en from '../resources/dictions/en.json';
import ru from '../resources/dictions/ru.json';
import hy from '../resources/dictions/hy.json';

const translations = {
    en,
    ru,
    hy
};

export default function translate(key, lang) {
    let text = translations[lang][key]
    if (text === '' || text === undefined) { text = translations['en'][key] }
    return text
}