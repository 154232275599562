import React, { useState, useEffect } from 'react';
import colors from "../../resources/styles/variables.js";
import { Life } from '../../components/GetIcons'
import './Home.scss';

const Home = () => {
    const [videoEnded, setVideoEnded] = useState(false);

    const handleVideoEnd = () => {
        setVideoEnded(true);
    };

    useEffect(() => {
        const homeVideo = document.querySelector('.home_video');

        const handleScroll = () => {
            if (videoEnded && window.scrollY === 0) {
                setVideoEnded(false);
                homeVideo.currentTime = 0;
                homeVideo.play();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            homeVideo.removeEventListener('ended', handleVideoEnd); // Cleanup for video event listener
        };
    }, [videoEnded]);

    return (
        <div className="home_section" id="home" >
            <video
                autoPlay={true}
                loop={false}
                muted={true}
                playsInline={true}
                defaultmuted={true}
                onEnded={handleVideoEnd}
                className="home_video">
                <source src={require('../../assets/home/video0.mp4')} type="video/mp4" />
            </video>
            <div className="life">
                <Life fillColor={colors.birch} />
            </div>
        </div>
    );
}

export default Home;
