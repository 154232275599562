import React from "react";
import './About.scss';
import Slider from 'react-slick';
import AboutTexts from './AboutTexts';
import { useLanguage } from "../../components/LanguageContext.js";
import translate from "../../components/GetTranslations.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = () => {
    const { language } = useLanguage();
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        rows: 1,
        arrows: false,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Slide duration of 2000ms (2 seconds),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const pageName = translate("about", language).toUpperCase();
    const cdm_nexus = translate("cdm_nexus", language);
    const cdm_nexus_is = translate("cdm_nexus_is", language);
    const aboutData = AboutTexts[language] || [];

    return (
        <div className="about-page-container" id="about" >
            <span className="page-name">{pageName}</span>
            <div className="about-page">
                <Slider {...settings}>
                    {aboutData.map((text, index) => (
                        <div key={index} className="about-item">
                            <div className="div1" />
                            <div className="div2" />
                            <p className="about-item-title">{text.title}</p>
                            <p className="about-item-text">{text.text}</p>
                        </div>
                    ))}
                </Slider>
                <p className="about-text"><span className="about-text-bold">{cdm_nexus}</span>{cdm_nexus_is}</p>
            </div>
        </div>
    )
}
export default About;
