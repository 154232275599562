// OurTeam.js

import React, { useState, useEffect, useRef } from "react";
import teamMembersData from "./TeamData";
import ReactGA from "react-ga4";
import translate from "../../components/GetTranslations.js";
import { Email } from "../../components/GetIcons.js";
import { useLanguage } from "../../components/LanguageContext";
import closeIcon from "../../assets/icons/close.png"; // Import the close icon
import "./Team.scss";
import colors from "../../resources/styles/variables.js";

const Team = () => {
  const popupRef = useRef(null);
  const { language } = useLanguage();
  const our_team = translate("our_team", language);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isPopupOpen) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }
  }, [isPopupOpen]);

  const handleMemberClick = (member) => {
    setSelectedMember(member);
    setIsPopupOpen(true);
    ReactGA.event({
      category: "Team",
      action: "Clicked",
      label: member.name,
    });
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleContainerClick = (event) => {
    // If the clicked element is not inside the popup-content area, close the popup
    if (!popupRef.current.contains(event.target)) {
      handleClosePopup();
    }
  };

  const currentTeamMembers = teamMembersData[language] || [];
  return (
    <div className="our-team" id="team">
      <h2 className="our-team_heading">{our_team}</h2>
      <div className="team-members">
        {currentTeamMembers.map((member) => (
          <div className="team-member" key={member.id}>
            <p className="team-member_role">{member.role}</p>
            <img
              src={member.photo}
              alt={member.name}
              className="team-member_photo"
              onClick={() => handleMemberClick(member)}
            />
            <div className="team-member_info">
              <h4 className="team-member_name">{member.name}</h4>
              <p className="team-member_description">
                {member.description.length > 150
                  ? member.description.slice(0, 150) + "..."
                  : member.description}
              </p>
              <a
                href={`mailto:${member.mail}`}
                target="_blank"
                rel="noreferrer"
                className="email"
              >
                <Email fillColor={colors.birch} />
                <p>{member.mail}</p>
              </a>
            </div>
          </div>
        ))}
      </div>
      {isPopupOpen && selectedMember && (
        <div className="popup" onClick={handleContainerClick}>
          <div className="popup-container" ref={popupRef}>
            <img
              src={closeIcon}
              alt="Close"
              className="close-popup"
              onClick={handleClosePopup}
            />
            <div className="popup-content">
              <img
                src={selectedMember.photo}
                alt={selectedMember.name}
                className="popup-photo"
              />
              <h2>{selectedMember.name}</h2>
              <p>{selectedMember.role}</p>
              <a
                href={`mailto:${selectedMember.mail}`}
                target="_blank"
                rel="noreferrer"
                className="email"
              >
                <Email />
                <p>{selectedMember.mail}</p>
              </a>
              <p>{selectedMember.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Team;
