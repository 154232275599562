import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import translate from "../GetTranslations.js";
import Logo from "../../assets/logo_colored.svg";
import "./Footer.scss";

const Footer = ({ type }) => {
  const { language } = useLanguage();

  const terms = translate("terms", language);
  const privacy = translate("privacy", language);
  const rights = translate("rights", language);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="logo">
            <Link to="/"> <img src={Logo} alt="Logo" /></Link>

            <div className="rights">
              <p >&copy; {new Date().getFullYear()} {rights}</p>
            </div>
          </div>
          <div className="links">
            {type !== 1 && (
              <>
                <Link to="/terms">{terms}</Link>
                <Link to="/politics">{privacy}</Link>
              </>
            )}
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
