import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TermsData from "./TermsData.json";
import PoliticsData from "./PoliticsData.json";
import { useLanguage } from "../../components/LanguageContext.js";
import translate from "../../components/GetTranslations.js";
import jsPDF from "jspdf";
import "./Styles.scss";

export function Terms() {
  const { language } = useLanguage();
  const terms = TermsData[language] || [];
  const termsOF = translate("termsOF", language);
  const downloadPdfDocument = (data, title) => {
    const doc = new jsPDF();

    let y = 10; // vertical offset for elements

    doc.setFontSize(20);
    doc.text(title, 10, y);
    y += 10; // Increase vertical offset

    data.forEach((item, index) => {
      doc.setFontSize(16);
      doc.text(`${index + 1}. ${item.title}`, 10, y);
      y += 10;

      doc.setFontSize(12);
      doc.text(item.text, 10, y);
      y += 20; // Adjust spacing based on your content
    });

    doc.save(`${title}.pdf`);
  };
  const processText = (text) => {
    return text.split("<br/>").filter((t) => t.trim() !== "");
  };
  const downloadTermsAsPDF = () => downloadPdfDocument(terms, termsOF);
  return (
    <>
      <Header type={1} />
      <div className="terms_politics">
        <div className="terms_politics_container">
          <h2>{termsOF}</h2>
          {terms.map((term, index) => {
            return (
              <div key={index} className="terms-item">
                <h4 className="terms-title">
                  {index + 1}. {term.title}
                </h4>
                {processText(term.text).map((item, subIndex) => (
                  <p className="terms-text" key={subIndex}>
                    {item}
                  </p>
                ))}
              </div>
            );
          })}
          {/* <button onClick={downloadTermsAsPDF}>Download PDF</button> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export function Politics() {
  const { language } = useLanguage();
  const politics = PoliticsData[language] || [];
  const politicsOF = translate("politicsOF", language);
  const downloadPdfDocument = (data, title) => {
    const doc = new jsPDF();

    let y = 10; // vertical offset for elements

    doc.setFontSize(20);
    doc.text(title, 10, y);
    y += 10; // Increase vertical offset

    data.forEach((item, index) => {
      doc.setFontSize(16);
      doc.text(`${index + 1}. ${item.title}`, 10, y);
      y += 10;

      doc.setFontSize(12);
      doc.text(item.text, 10, y);
      y += 20; // Adjust spacing based on your content
    });

    doc.save(`${title}.pdf`);
  };

  const downloadPoliticsAsPDF = () => downloadPdfDocument(politics, politicsOF);
  return (
    <>
      <Header type={1} />
      <div className="terms_politics">
        <div className="terms_politics_container">
          <h2>{politicsOF}</h2>
          {politics.map((text, index) => (
            <div key={index} className="terms-item">
              <h4 className="terms-title">
                {index + 1}. {text.title}
              </h4>
              <p className="terms-text">{text.text}</p>
            </div>
          ))}
          {/* <button onClick={downloadPoliticsAsPDF}>Download PDF</button> */}
        </div>
      </div>
      <Footer />
    </>
  );
}
